function hubspotLoader(UserService, UserSession) {
  // https://developers.hubspot.com/docs/api/conversation/visitor-identification#tab-2

  if (import.meta.env.VITE_APP_ENVIRONMENT !== 'production') return;

  function _onConversationsAPIReady() {
    UserService.getHubspotVisitorToken()
      .then(function (res) {
        const token = res && res.token;
        _loadConversationWidget(token);
      })
      .catch(function (err) {
        if (err.status) {
          console.log(err.status);
        }
        throw err;
      });
  }

  function _loadConversationWidget(token) {
    window.hsConversationsSettings = {
      identificationEmail: UserSession.getCurrentUserEmail(),
      identificationToken: token,
    };

    if (window.HubSpotConversations && window.HubSpotConversations.widget) {
      return window.HubSpotConversations.widget.load();
    }
  }

  /*
    configure window.hsConversationsSettings if needed.
  */
  window.hsConversationsSettings = {
    loadImmediately: false,
  };
  /*
   If external API methods are already available, use them.
  */
  if (window.HubSpotConversations) {
    _onConversationsAPIReady();
  } else {
    /*
      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
      These callbacks will be called once the external API has been initialized.
    */
    window.hsConversationsOnReady = [_onConversationsAPIReady];
  }
}

export { hubspotLoader };
